import * as React from 'react'
import {Link} from "gatsby"; 
import Block from './common/block'
const ListPartner = (props: any) => {
    const data = props.data.partner
    const className = props.className ?  props.className : ''
    return (
        <>
            <Block className={'tpl-block icons-link-block text-center  list-partner ' + props.background + ' ' + className } >
                <div className="container">
                    <ul className="list-check star-benefits row justify-content-start">
                        {
                            data.list_partner.map((dt: any)=>(
                                <li className="list-check__item text-center col-md-4 col-lg-2" key={dt.id}>
                                    <Link to= {dt.link} className="d-block mb-4 ratio ratio-1x1">
                                        <img src={dt.image} alt={dt.title}  />
                                    </Link>
                                    <Link to= {dt.link} className="list-check__text text-decoration-none">
                                        {dt.title}
                                        <span> ➜</span>
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </Block>
        </>
    )
}

export default ListPartner
