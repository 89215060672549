import * as React from "react"
import Layout from "../components/common/layout"
import Heading from "../components/common/heading" 
import LiveStream from "../components/common/live-stream-table" 
import {  graphql, Link } from "gatsby"
import { Helmet } from 'react-helmet'
import PreFooter from "../components/pre-footer"
import SearchBar from "../components/common/search-bar"
import IconLink from "../components/icon-link"
import ListPartner from "../components/list-partner"
import Person from "../components/common/person" 
// markup 
const locations = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const liveStreamData = _data.find((item: any) => item.block === 'livestream')
  const hero = _data.find((item: any) => item.block === 'hero')
  const dataPrefooter = _data.find((item: any) => item.block === 'prefooter')
  const dataIconLink = _data.find((item: any) => item.block === 'icon-link')
  const partner = _data.find((item: any) => item.block === 'list-partner')
  
  return (
    <Layout>
      <Helmet
        title='Locations detail'
      />
       <SearchBar />
       <section className="hero-location ">
            <div className="top text-center bg-blue " >
              <div className="container">
                <h2 className="hero-location-title">Suburb</h2>
                <div className="hero-location-name font-weight-bold">VenueName</div>
                <div className="hero-location-address">123 address street, suburb, NSW, 2000</div>
              </div>
            </div>
            <div className=" middle">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                      <div className="image ratio ratio-16x9">
                        <img src="/images/location-hero.jpg" alt="" />
                      </div>
                  </div>
                  <div className="col-md-4 d-none d-md-block">
                    <div className="image ratio ratio-1x1">
                        <img src="/images/map-1.jpg" alt="" />
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom">
              <div className="container">
                <div className="row ">
                  <div className="col-md-8">
                    <div className="text row">
                      <div className="col-md-6">
                        <div className="text">
                          <h4>About this camp</h4>
                          <p>
                            Live Life Get Active provides this free fitness camp and is open to all members of the public. There are the following activities on offer, Active Yoga, Active Boxing, Active X Training, and you can learn more about them below.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text">
                          <h4>Sessions</h4>
                          <p>
                            Sessions are available at 9:30 am and run Monday, Tuesday, Wednesday, Thursday, Friday during school terms. For more details see the timetable below.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-block d-md-none map-mobile">
                      <div className="image ratio ratio-1x1">
                          <img src="/images/map-1.jpg" alt="" />
                        </div>
                    </div>
                    <div className="text">
                      <h4>Location</h4>
                      <p>The Claymore - Badgally Reserve camp is located in the suburb of Blairmount, part of the Campbelltown (City) Local Government Area. The camp is funded by Landcom and has been running since October 2020.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
       </section>
       <div className="partner">
          <Heading 
            className='mb-0'
            title='Partner' background='bg-white'/>
          <ListPartner data={partner.data[0]} background="bg-white"></ListPartner>
       </div>

       <div className="get-start-block tpl-block bg-blue-bale">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-7">
                <div className="me-md-4 text">
                  <h4>How to get started</h4>
                  <p>To join this camp all you have to do is complete our registration form and book into as many classes as you like. All our classes are FREE and registration takes about 10 mins.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-5">
                <div className="box bg-blue  text-center">
                  <div className="box-title font-weight-bold">There are no joining fees, no class fees, everything we offer is free.</div>
                  <a href="" className="btn btn-primary">Join + book now</a>
                </div>
              </div>
            </div>
          </div>
       </div>
      
       <div className="activity-trainer">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 activity-col">
                <Heading 
                  className='mb-0'
                  title='Activities' background='bg-white'/>
                  <IconLink data={dataIconLink.data[0]} background="bg-white"></IconLink>
              </div>
              <div className="col-lg-6 trainer-col">
                <Heading 
                  className='mb-0'
                  title='Trainers' background='bg-white'/>
                <div className="row list-trainner-box">
                  <div className="col-md-4">
                    <Person image='/images/caro.svg' name='Adrian Lee' size='lg' vertical={true} /> 
                  </div>
                  <div className="col-md-4">
                    <Person image='/images/caro.svg' name='Adrian Lee' size='lg'  vertical={true} /> 
                  </div>
                </div> 
              </div>
            </div>
          </div>
       </div>
        <div className="live-stream-table mb-5 pb-3">
          <Heading 
            className='mb-0'
            title='Session times' background='bg-white'/>
          <div className="container">
            <div className="live-stream-title">Monday 20th June - Sun 26th June</div>
            <LiveStream data={liveStreamData.data[0].data_live_stream[0]} />
            <LiveStream data={liveStreamData.data[1].data_live_stream[0]} />
            <LiveStream data={liveStreamData.data[0].data_live_stream[0]} />
            <div className="live-stream-title">Monday 20th June - Sun 26th June</div>
            <LiveStream data={liveStreamData.data[0].data_live_stream[0]} />
            <LiveStream data={liveStreamData.data[1].data_live_stream[0]} />
            <LiveStream data={liveStreamData.data[0].data_live_stream[0]} />
          </div>
        </div>
        <PreFooter data={dataPrefooter.data[0]}  background="bg-blue"></PreFooter> 
    </Layout>
  )
}

export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["location-detail"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            date_from
            date_to
            data_live_stream {
              date
              data {
                id
                time
                status
                class
                trainer {
                  name
                  image
                }
              }
            }

            prefooter_data {
              title
              content
              links {
                id
                text
                url
              }
            } 
            icons {
              icon_link {
                id
                title
                image
                link
              }
            }
            partner {
              list_partner{
                id
                title
                image
                link
              }
            }
            sub_menu{
            title
            links {
                id
                text
                link
                active
            }
            }
          }
        }
      }
    }
  }
`
export default locations
